import React from "react";
import styles from "./header.module.css";
import settings from "../assets/header/Frame 18.png";
import user from "../assets/header/photo.png";
import logout from "../assets/header/Sign_in_squre_fill.png";
import img1 from "../assets/header/Coat_of_Arms_of_Dushanbe (3) 1.png";
import img2 from "../assets/header/logo_mtic.png";

const Header = ({ handleLogout }) => {
  return (
    <>
      <div className={styles.header}>
        <p className={styles.mti}>МТИ</p>
        <div className={styles.menu}>
          <img src={settings} alt="Настройки" />
          <img src={user} alt="Пользователь" />
          <img src={logout} onClick={handleLogout} alt="Выход" />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.header_container}>
        <img src={img1} alt="Герб города Душанбе" />
        <h1>
          МАРКАЗИ ТАҲЛИЛӢ <br /> ИТТИЛООТИИ ШАҲРИ ДУШАНБЕ
        </h1>
        <img src={img2} alt="Логотип МТИЦ" />
      </div>
    </>
  );
};

export default Header;
