import "./App.css";
import React, { useState, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Import missing components
import User from "./components/getuser/User";
import Add from "./components/adduser/Add";
import Edit from "./components/updateuser/Edit";
import LoginForm from "./components/LoginForm/LoginForm";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: isLoggedIn ? (
        <User handleLogout={handleLogout} />
      ) : (
        <LoginForm onLogin={handleLogin} />
      ),
    },
    {
      path: "/add",
      element: isLoggedIn ? <Add /> : <LoginForm onLogin={handleLogin} />,
    },
    {
      path: "/edit/:id",
      element: isLoggedIn ? <Edit /> : <LoginForm onLogin={handleLogin} />,
    },
    {
      path: "/users",
      element: isLoggedIn ? <User handleLogout={handleLogout} /> : <LoginForm onLogin={handleLogin} />,
    },
  ]);

  return (
    <div className="App">
      {isLoggedIn ? <></> : null}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
