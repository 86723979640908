import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./UserTable5.module.css";
import progress from "../../assets/tablitsa/progress.png";
import search_icon from "../../assets/tablitsa/Search.png";
import deletee from "../../assets/tablitsa/trash.png";

const UserTable5 = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("http://193.57.210.140:9000/api/data4");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteUser = async (id) => {
    if (!id) {
      console.error("Invalid ID");
      toast.error("Invalid ID");
      return;
    }

    try {
      await axios.delete(`http://193.57.210.140:9000/api/data4/${id}`);
      toast.success("Данные успешно удалены");
      fetchData(); // Update list after deleting data
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Произошла ошибка при удалении данных");
    }
  };

  return (
    <>
      <div className={styles.searchContainer}>
        <div className={styles.div1_input}>
          <input
            type="text"
            // value={searchQuery1}
            // onChange={handleSearchChange1}
            placeholder="Ҷустуҷу аз рӯйи ном"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            <img src={search_icon} alt="Search" className={styles.icon} />
          </button>
        </div>
        <input
          type="text"
          // value={searchQuery2}
          // onChange={handleSearchChange2}
          placeholder="Ҷустуҷу аз рӯйи насаб"
          className={styles.input}
        />
        <input
          type="date"
          // value={searchQuery3}
          // onChange={handleSearchChange3}
          placeholder="Чустучу аз рӯйи ном 3"
          className={styles.input}
        />
      </div>
      <div className={styles.tableContainer}>
        {users.length > 0 ? (
          users.map((user) => {
            const [value, label] = user.label.split(" - "); // Split the combined label

            return (
              <div key={user.id} className={styles.tableRow}>
                <img src={progress} alt="" />
                <div className={styles.div_divs_tablitsa}>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Номи Рохбар</p>
                    <div className={styles.tableCell}>
                      <p>{value}</p>
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Мактабе ки вай Рохбар</p>
                    <div className={styles.tableCell}>
                      <p>{label}</p>
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Амал</p>
                    <div className={styles.tableCell}>
                      <button
                        className={styles.deleteButton}
                        onClick={() => deleteUser(user.id)}
                      >
                        <img src={deletee} alt="Delete" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>Нет данных для отображения</p>
        )}
      </div>
    </>
  );
};

export default UserTable5;
