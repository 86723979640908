import React, { useEffect, useState } from "react";
import pencil from "../../assets/tablitsa/pencil-alt.png";
import deletee from "../../assets/tablitsa/trash.png";
import progress from "../../assets/tablitsa/progress.png";
import search_icon from "../../assets/tablitsa/Search.png";

import { Link } from "react-router-dom";
import styles from "./UserTable.module.css";
import axios from "axios";
import toast from "react-hot-toast";

const UserTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("http://193.57.210.140:8000/api/getall");
      setUsers(response.data);
    };
    fetchData();
  }, []);

  console.log(setUsers);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`http://193.57.210.140:8000/api/delete/${userId}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
      toast.success("Довталаб бомуваффақият нест карда шуд", {
        position: "top-right",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange1 = (event) => {
    setSearchQuery1(event.target.value);
  };

  const handleSearchChange2 = (event) => {
    setSearchQuery2(event.target.value);
  };

  const handleSearchChange3 = (event) => {
    setSearchQuery3(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.fname.toLowerCase().includes(searchQuery1.toLowerCase()) &&
      user.lname.toLowerCase().includes(searchQuery2.toLowerCase()) &&
      user.password.toLowerCase().includes(searchQuery3.toLowerCase())
  );

  return (
    <>
      <div className={styles.searchContainer}>
        <div className={styles.div1_input}>
          <input
            type="text"
            value={searchQuery1}
            onChange={handleSearchChange1}
            placeholder="Ҷустуҷу аз рӯйи ном"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            <img src={search_icon} alt="Search" className={styles.icon} />
          </button>
        </div>
        <input
          type="text"
          value={searchQuery2}
          onChange={handleSearchChange2}
          placeholder="Ҷустуҷу аз рӯйи насаб"
          className={styles.input}
        />
        <input
          type="date"
          value={searchQuery3}
          onChange={handleSearchChange3}
          placeholder="Чустучу аз рӯйи ном 3"
          className={styles.input}
        />
      </div>
      <div className={styles.tableContainer}>
        {filteredUsers.map((user, index) => (
          <div key={index} className={styles.tableRow}>
            <img src={progress} alt="" />
            <div className={styles.div_divs_tablitsa}>
              <div className={styles.divs_tablitsa}>
                <p className={styles.divs_tablitsa_p}>Ном</p>
                <div className={styles.tableCell}>
                  <p>{user.fname}</p>
                </div>
              </div>
              <div className={styles.divs_tablitsa}>
                <p className={styles.divs_tablitsa_p}>Насаб</p>
                <div className={styles.tableCell}>
                  <p>{user.lname}</p>
                </div>
              </div>
              <div className={styles.divs_tablitsa}>
                <p className={styles.divs_tablitsa_p}>Саннаи таввалуд</p>
                <div className={styles.tableCell}>
                  <p>{user.password}</p>
                </div>
              </div>
              <div className={styles.divs_tablitsa}>
                <p className={styles.divs_tablitsa_p}> Номер телефона</p>
                <div className={styles.tableCell}>
                  <p>{user.email}</p>
                </div>
              </div>
            </div>
            <div className={styles.divs_tablitsa}>
              <p className={styles.divs_tablitsa_p}>Амалхо</p>
              <div className={styles.tableCell}>
                <Link to={`/edit/${user._id}`} className={styles.editButton}>
                  <img src={pencil} alt="Edit" />
                </Link>
                <button
                  className={styles.deleteButton}
                  onClick={() => deleteUser(user._id)}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            </div>
          </div>
        ))
        }
      </div>
    </>
  );
};

export default UserTable;
