import React from "react";
import styles from "./Footer.module.css";
import appStoreLogo from "../assets/footer/app-store-logo.png";
import googlePlayLogo from "../assets/footer/google-play-logo.png";
import qrCodeImage from "../assets/footer/qr-code.png";
import twitter from "../assets/footer/twitter - FontAwesome.png";
import facebook from "../assets/footer/facebook-official - FontAwesome.png";
import twitch from "../assets/footer/twitch - FontAwesome.png";
import youtube from "../assets/footer/youtube-play - FontAwesome.png";
import instagram from "../assets/footer/instagram - FontAwesome.png";
import phone from "../assets/footer/Vector (2).png";
import email from "../assets/footer/Vector (3).png";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerDiv1}>
        <div className={styles.footerDiv1_1}>
          <div className={styles.appLinks}>
            <a
              href="https://apps.apple.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStoreLogo} alt="App Store" />
            </a>
            <a
              href="https://play.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlayLogo} alt="Google Play" />
            </a>
          </div>
          <img src={qrCodeImage} alt="QR Code" className={styles.qrCode} />
        </div>
        <div className={styles.footerDiv1_2}>
          <div className={styles.contact}>
            <h3>Контакты</h3>
            <div className={styles.contactInfo}>
              <p>
                <img src={phone} alt="Phone" className={styles.phone}/> +992 907266062
              </p>
              <p>
                <img src={email} alt="Email" className={styles.email}/> bilolshodiev05@gmail.com
              </p>
            </div>
          </div>
          <div className={styles.company}>
            <h3>Компания</h3>
            <ul>
              <li>Дохилкунии довталаб</li>
              <li>Дохилкунии муссиса</li>
              <li>Дохилкунии озмун</li>
              <li>Дохилкунии мусобикахо</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.khat}></div>
      <div className={styles.footerDiv2}>
        <div className={styles.socialLinks}>
          <a href="https://twitter.com">
            <img src={twitter} alt="Twitter" className={styles.twitter} />
          </a>
          <a href="https://facebook.com">
            <img src={facebook} alt="Facebook" className={styles.facebook} />
          </a>
          <a href="https://twitch.tv">
            <img src={twitch} alt="Twitch" className={styles.twitch} />
          </a>
          <a href="https://youtube.com">
            <img src={youtube} alt="Youtube" className={styles.youtube} />
          </a>
          <a href="https://instagram.com">
            <img src={instagram} alt="Instagram" className={styles.instagram} />
          </a>
        </div>
        <div className={styles.footerBottom}>
          <p>© 2024 Все права защищены</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
