import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const MyComponent = ({ onSelectChange }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  console.log("options", options, "setOptions", setOptions);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://193.57.210.140:9000/api/data1");
        setOptions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (selectedOption) => {
    console.log("MyComponent selected option:", selectedOption);
    setSelectedOption(selectedOption);
    onSelectChange(selectedOption);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleSelectChange}
      placeholder={"Муассисаҳо"}
    />
  );
};

export default MyComponent;
