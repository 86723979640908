import React, { useState } from "react";
import styles from "./user.module.css";
import AddButtons from "../AddButtons/AddButtons";
import Header from "../header/header";
import Footer from "../Footer/Footer";

import UserTable from "../UserTables/UserTable1/UserTable";
import UserTable2 from "../UserTables/UserTable2/UserTable2";
import UserTable3 from "../UserTables/UserTable3/UserTable3";
import UserTable4 from "../UserTables/UserTable4/UserTable4";
import UserTable5 from "../UserTables/UserTable5/UserTable5";

import AddData1 from "../AddData/AddData1/AddData1";
import AddData2 from "../AddData/AddData2/AddData2";
import AddData3 from "../AddData/AddData3/AddData3";
import AddData4 from "../AddData/AddData4/AddData4";
import Marquee from "../Marquee/Marquee";

const User = ({ handleLogout }) => {
  const [showAddData1, setShowAddData1] = useState(false);
  const [showAddData2, setShowAddData2] = useState(false);
  const [showAddData3, setShowAddData3] = useState(false);
  const [showAddData4, setShowAddData4] = useState(false);

  const [activeComponent, setActiveComponent] = useState("dovtalab");

  const [data, setData] = useState([]);

  const handleAddData = (newData) => {
    setData([...data, newData]);
  };

  const handleDeleteData = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  return (
    <>
      <Header handleLogout={handleLogout} />
      <div className={styles.container}>
        <AddButtons
          setShowAddData1={setShowAddData1}
          setShowAddData2={setShowAddData2}
          setShowAddData3={setShowAddData3}
          setShowAddData4={setShowAddData4}
        />
        <div className={styles.Links_Container}>
          <div className={styles.Links}>
            <button
              className={`${styles.Link} ${
                activeComponent === "dovtalab" ? styles.active : ""
              }`}
              onClick={() => setActiveComponent("dovtalab")}
            >
              Довталаб
            </button>
            <button
              className={`${styles.Link} ${
                activeComponent === "muasisa" ? styles.active : ""
              }`}
              onClick={() => setActiveComponent("muasisa")}
            >
              Муассиса
            </button>
            <button
              className={`${styles.Link} ${
                activeComponent === "ozmun" ? styles.active : ""
              }`}
              onClick={() => setActiveComponent("ozmun")}
            >
              Озмун
            </button>
            <button
              className={`${styles.Link} ${
                activeComponent === "musobikaho" ? styles.active : ""
              }`}
              onClick={() => setActiveComponent("musobikaho")}
            >
              Мусобикахо
            </button>
            <button
              className={`${styles.Link} ${
                activeComponent === "rohbarho" ? styles.active : ""
              }`}
              onClick={() => setActiveComponent("rohbarho")}
            >
              Рохбар
            </button>
          </div>
          <div>
            {activeComponent === "dovtalab" && (
              <UserTable users={data} deleteUser={handleDeleteData} />
            )}
            {activeComponent === "muasisa" && (
              <UserTable2 users={data} deleteUser={handleDeleteData} />
            )}
            {activeComponent === "ozmun" && (
              <UserTable3 users={data} deleteUser={handleDeleteData} />
            )}
            {activeComponent === "musobikaho" && (
              <UserTable4 users={data} deleteUser={handleDeleteData} />
            )}
            {activeComponent === "rohbarho" && (
              <UserTable5 users={data} deleteUser={handleDeleteData} />
            )}
          </div>
        </div>
        {showAddData1 && (
          <div className={styles.modal}>
            <div
              className={`${styles.modalContent} ${styles.addData1Container}`}
            >
              <span
                className={styles.close}
                onClick={() => setShowAddData1(false)}
              >
                &times;
              </span>
              <AddData1 onAddData={handleAddData} />
            </div>
          </div>
        )}

        {showAddData2 && (
          <div className={styles.modal}>
            <div
              className={`${styles.modalContent} ${styles.addData2Container}`}
            >
              <span
                className={styles.close}
                onClick={() => setShowAddData2(false)}
              >
                &times;
              </span>
              <AddData2 onAddData={handleAddData} />
            </div>
          </div>
        )}

        {showAddData3 && (
          <div className={styles.modal}>
            <div
              className={`${styles.modalContent} ${styles.addData2Container}`}
            >
              <span
                className={styles.close}
                onClick={() => setShowAddData3(false)}
              >
                &times;
              </span>
              <AddData3 onAddData={handleAddData} />
            </div>
          </div>
        )}

        {showAddData4 && (
          <div className={styles.modal}>
            <div
              className={`${styles.modalContent} ${styles.addData2Container}`}
            >
              <span
                className={styles.close}
                onClick={() => setShowAddData4(false)}
              >
                &times;
              </span>
              <AddData4 onAddData={handleAddData} />
            </div>
          </div>
        )}
      </div>
      <Marquee />
      <Footer />
    </>
  );
};

export default User;
