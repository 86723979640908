import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./AddData3.module.css"; // Импортируйте стили как модуль

const AddData3 = ({ onAddData }) => {
  const [formData, setFormData] = useState({
    value: "",
    label: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const combinedLabel = `${formData.value} - ${formData.label}`;
    const dataToSend = { value: formData.value, label: combinedLabel };

    try {
      await axios.post("http://193.57.210.140:9000/api/add3", dataToSend);
      toast.success("Данные успешно сохранены");
      onAddData(dataToSend); // Передайте новые данные родительскому компоненту
    } catch (error) {
      console.error(error);
      toast.error("Произошла ошибка при сохранении данных");
    }
  };

  return (
    <div className={styles.addData3Container}>
      <h2>Дохилкунии Мусобикахо</h2>
      <form onSubmit={handleSubmit}>
        <label className={styles.labelField} htmlFor="value">
          Номи Мусобика
        </label>
        <input
          type="text"
          name="value"
          id="value"
          value={formData.value}
          onChange={handleChange}
          placeholder="номи мусобика"
          className={styles.inputField}
          required
        />
        <label className={styles.labelField} htmlFor="label">
          Ташкилотчии Мусобика
        </label>
        <input
          type="text"
          name="label"
          id="label"
          value={formData.label}
          onChange={handleChange}
          placeholder="ташкилотчии мусобика"
          className={styles.inputField}
          required
        />
        <button type="submit" className={styles.hh}>
          Навсози
        </button>
      </form>
    </div>
  );
};

export default AddData3;
