import React from "react";
import "./Marquee.css";
import image1 from "../assets/marcuee/banertj 1.png";
import image2 from "../assets/marcuee/banertj 2.png";

const Banner = () => {
  return (
    <div className="marquee">
      <div className="marquee-content">
        {[...Array(20)].map((_, index) => (
          <React.Fragment key={index}>
            <img src={image1} alt="First Slide" className="marquee-image_1" />
            <img src={image2} alt="Second Slide" className="marquee-image" />
          </React.Fragment>
        ))}
      </div>
      <div className="marquee-content">
        {[...Array(20)].map((_, index) => (
          <React.Fragment key={index}>
            <img src={image1} alt="First Slide" className="marquee-image_1" />
            <img src={image2} alt="Second Slide" className="marquee-image" />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Banner;
