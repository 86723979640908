import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./AddData4.module.css"; // Импортируйте стили как модуль
import MyComponent from "../MyComponent"; // Импортируйте ваш компонент MyComponent

const AddData4 = ({ onAddData }) => {
  const [formData, setFormData] = useState({
    value: "",
    label: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Установите label на основе выбранной опции, если необходимо
    setFormData({
      ...formData,
      label: selectedOption ? selectedOption.label : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const combinedLabel = `${formData.value} - ${formData.label}`;
    const dataToSend = { value: formData.value, label: combinedLabel };

    try {
      await axios.post("http://193.57.210.140:9000/api/add4", dataToSend);
      toast.success("Данные успешно сохранены");
      onAddData(dataToSend); // Передайте новые данные родительскому компоненту
    } catch (error) {
      console.error(error);
      toast.error("Произошла ошибка при сохранении данных");
    }
  };

  return (
    <div className={styles.addData4Container}>
      <h2>Дохилкунии Рохбар</h2>
      <form onSubmit={handleSubmit}>
        <label className={styles.labelField} htmlFor="value">
          Номи Рохбар
        </label>
        <input
          type="text"
          name="value"
          value={formData.value}
          onChange={handleChange}
          placeholder="номи рохбар"
          className={styles.inputField}
          required
        />
        <label className={styles.labelField} htmlFor="label">
          Мактабе ки вай рохбар аст
        </label>
        <MyComponent onSelectChange={handleSelectChange} />
        <button type="submit" className={styles.hh}>
          Навсози
        </button>
      </form>
    </div>
  );
};

export default AddData4;
