import React from "react";
import { Link } from "react-router-dom";
import styles from "./AddButtons.module.css";

const AddButtons = ({
  setShowAddData1,
  setShowAddData2,
  setShowAddData3,
  setShowAddData4,
}) => {
  return (
    <div className={styles.container}>
      <Link to={"/add"} className={styles.item}>
        <div className={`${styles.icon} ${styles.dovtalabIcon}`}></div>
        <div className={styles.text}>Дохтлкунии довталаб</div>
      </Link>
      <div className={styles.item} onClick={() => setShowAddData1(true)}>
        <div className={`${styles.icon} ${styles.muasisaIcon}`}></div>
        <div className={styles.text}>Дохтлкунии муассиса</div>
      </div>
      <div className={styles.item} onClick={() => setShowAddData2(true)}>
        <div className={`${styles.icon} ${styles.ozmunIcon}`}></div>
        <div className={styles.text}>Дохтлкунии озмун</div>
      </div>
      <div className={styles.item} onClick={() => setShowAddData3(true)}>
        <div className={`${styles.icon} ${styles.musobikahoIcon}`}></div>
        <div className={styles.text}>Дохтлкунии мусобикахо</div>
      </div>
      <div className={styles.item} onClick={() => setShowAddData4(true)}>
        <div className={`${styles.icon} ${styles.rohbarIcon}`}></div>
        <div className={styles.text}>Дохтлкунии рохбар</div>
      </div>
    </div>
  );
};

export default AddButtons;
