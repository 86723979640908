import React, { useState } from "react";
import style from "./LoginForm.module.css";

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ username: false, password: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({ username: false, password: false });

    if (!username) {
      setError((prevError) => ({ ...prevError, username: true }));
    }
    if (!password) {
      setError((prevError) => ({ ...prevError, password: true }));
    }
    if (!username || !password) {
      return;
    }

    try {
      const response = await fetch("http://193.57.210.140:5000/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        onLogin();
      } else {
        setError({ username: true, password: true });
      }
    } catch (error) {
      console.error("Ошибка при входе:", error);
    }
  };

  return (
    <div className={style.content}>
      <form onSubmit={handleSubmit} className={style.formAuth}>
        <h2 className={style.daromadan}>Дохилшавӣ</h2>
        <div className={style.inputContainer}>
          {error.username && (
            <span className={style.errorMessage}>Воридшавӣ нодуруст</span>
          )}
          <input
            className={`${style.inputAuth} ${
              error.username ? style.errorInput : ""
            }`}
            type="text"
            placeholder="Логин"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className={style.inputContainer}>
          {error.password && (
            <span className={style.errorMessage}>Рамзи нодуруст</span>
          )}
          <input
            className={`${style.inputAuth} ${
              error.password ? style.errorInput : ""
            }`}
            type="password"
            placeholder="Рамз"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className={style.hh}>
          Тасдиқ
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
